:root {
  --tw-color: #000;
  --tw-bg-color: #fff;
  --tw-link-color: #3388cc;
  --tw-link-color-hover: #55aaee;
  --tw-border-radius: 5px;
  --tw-font-family: Roboto;

  --tw-content-bg-color: var(--tw-bg-color);
  --tw-content-width: 100%;
  --tw-content-margin: inherit;
  --tw-sidebar-bg-color: var(--tw-bg-color);

  --tw-sidebar-width: 300px;
  --tw-sidebar-border: 1px solid #ccc;
  --tw-sidebar-border-radius: var(--tw-border-radius);
  --tw-sidebar-margin: 0 10px 0 0;
}

@import './material-variables';
